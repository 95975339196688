export const menuItems = [
  {
    id: "overview", //not change id
    name: "Revenue",
    path: "/overview",
    icon: "fa-chart-pie",
    show: true, // Just for AppHeader Menu
    submenu: [], //leave submenu empty if needed it will be dynamically updated
  },
  {
    id: "location",
    name: "Locations",
    path: "/location",
    icon: "fa-location-dot",
    show: false,
    submenu: [],
  },
  {
    id: "bid",
    name: "Events",
    path: "/events",
    icon: "fa-bolt",
    show: true,
    submenu: [],
  },
  {
    id: "profile",
    name: "Profile",
    path: "/profile",
    icon: "fa-user",
    show: true,
    submenu: [],
  },
];
