<template>
  <app-sidebar v-if="accountExists" :show-sidebar="showSidebar" />
  <div class="flex-grow dark:bg-dark-50 min-h-screen">
    <div
      class="text-md flex z-40 sticky top-0 justify-between items-center py-4 px-6 text-semibold shadow-md text-dark-50 bg-light-50 dark:bg-dark-50 dark:text-light-100 dark:shadow-neutral-800/40"
    >
      <div class="flex justify-center items-center">
        <button
          v-if="accountExists"
          class="mr-4 flex justify-center items-center"
          @click.stop="showSidebar = !showSidebar"
        >
          <font-awesome-icon
            class="flex justify-center items-center align-middle"
            :icon="['fas', 'fa-bars']"
          />
        </button>
        <div
          class="normal-case font-semibold flex justify-center items-center align-middle dark:text-light-100"
        ></div>
      </div>
      <div class="flex lg:flex-row flex-grow justify-end align-center">
        <ul
          class="flex text-center lg:flex-grow lg:flex-row justify-end duration-700 ease-in items-center"
        >
          <li class="lg:hidden">
            <button class="mr-6" @click.stop="navbarOpen = !navbarOpen">
              <font-awesome-icon
                class="align-middle"
                :icon="['fas', 'ellipsis-vertical']"
              />
            </button>
          </li>
          <li class="lg:block" :class="[navbarOpen ? 'block ' : 'hidden']">
            <span
              v-if="navbarOpen"
              class="fixed w-[190px] bg-light-50 rounded-md shadow-2xl z-50 top-[60px] right-[55px] p-1 cursor-pointer text-light-100 dark:bg-dark-100"
            >
              <ul
                class="text-gray-200 font-semibold text-[14px] dark:bg-dark-100"
              >
                <li>
                  <AppToggle
                    :descriptionLeft="'Light'"
                    :iconLeft="'sun'"
                    :descriptionRight="'Dark'"
                    :iconRight="'moon'"
                    :switchStatus="storeToggle.darkMode"
                    @click="storeToggle.toggleDarkMode"
                  />
                </li>
              </ul>
            </span>
            <AppToggle
              class="mx-5"
              v-show="!navbarOpen"
              :descriptionLeft="'Light'"
              :iconLeft="'sun'"
              :descriptionRight="'Dark'"
              :iconRight="'moon'"
              :switchStatus="storeToggle.darkMode"
              @click="storeToggle.toggleDarkMode"
            />
          </li>
          <li>
            <button
              @click="openMenu = !openMenu"
              class="rounded-full cursor-pointer"
            >
              <div class="flex items-center">
                <div
                  class="bg-green-50 flex h-8 w-8 shrink-0 grow-0 shadow-md items-center justify-center rounded-full text-light-100"
                >
                  <div class="uppercase dark:text-light-100">
                    {{ name.slice(0, 1) }}
                  </div>
                </div>
                <div
                  v-if="!!name"
                  class="text-[14px] ml-1.5 font-semibold text-black-50 dark:text-light-100"
                >
                  {{ name }}
                </div>
                <font-awesome-icon
                  :icon="
                    !openMenu ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']
                  "
                  @click="openMenu ? false : true"
                  class="ml-1.5 mr-1.5"
                />
              </div>
              <span
                v-if="openMenu"
                class="fixed w-[190px] bg-blue-50 rounded-md shadow-2xl z-50 top-[60px] right-[25px] p-1 cursor-pointer text-light-100 dark:bg-dark-100"
              >
                <ul
                  class="text-gray-200 font-semibold text-[14px] dark:bg-dark-100"
                >
                  <li
                    class="pb-1"
                    v-for="(item, index) in menuItems"
                    :key="index"
                  >
                    <router-link
                      v-if="item.show"
                      :to="item.path"
                      class="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-green-50 transition-colors duration-200 text-light-100 cursor-pointer"
                    >
                      <font-awesome-icon
                        class="w-4 h-4 text-light-100 transition duration-75 group-hover:text-gray-800"
                        :icon="['fas', item.icon]"
                      />
                      <span class="">{{ item.name }}</span>
                    </router-link>
                  </li>
                  <LogoutButton />
                </ul>
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <!-- Working with Breadcrumbs -->
    <figcaption
      class="flex items-center space-x-4 text-left mt-8 px-6 z-10 text-sm align-middle mb-4 text-semibold text-dark-50 bg-light-50 dark:bg-dark-50 dark:text-light-100"
    >
      <div
        class="bg-green-50 flex h-12 w-12 shrink-0 grow-0 shadow-md items-center justify-center rounded-md"
      >
        <font-awesome-icon color="#F5F5F5" :icon="['fas', 'fa-house']" />
      </div>
      <div>
        <div
          class="text-slate-900 font-semibold capitalize dark:text-light-100 pl-1"
        >
          Dashboard
        </div>
        <div class="text-sm leading-6 text-gray-700 dark:text-light-100">
          <div
            class="normal-case font-semibold flex dark:text-light-50 m-0 p-0"
          >
            <v-breadcrumbs :items="crumbs" class="p-0"></v-breadcrumbs>
          </div>
        </div>
      </div>
      <div
        class="col-end-3 col-span-1 flex items-center lg:flex-row flex-grow justify-end align-center"
      >
        <div class="grid grid-cols-1 gap-4 mt-3 mb-8 justify between">
          <AppToggle
            :switchStatus="storeToggle.autoUpdateStatus"
            @click="storeToggle.toggleAutoUpdate"
            :descriptionRight="'Update every 10 seconds.'"
          />
        </div>
      </div>
    </figcaption>
    <main role="main" class="px-6 mx-auto sm:ml-64 pb-16 py-6">
      <router-view
        :key="$route.params[$route.meta.watchParam]"
        v-slot="{ Component, route }"
      >
        <transition name="fade" mode="out-in">
          <div :key="route.name">
            <component :is="Component"></component>
          </div>
        </transition>
      </router-view>
    </main>
  </div>
</template>
<script setup lang="ts">
import AppSidebar from "@/components/AppSidebar.vue";
import { ref, computed } from "vue";
import { useMsal } from "../composables/useMsal";
import { menuItems } from "@/includes/constants";
import LogoutButton from "@/components/LogoutButton.vue";
import { useRoute } from "vue-router";
import AppToggle from "@/components/AppToggle.vue";
import { useToggle } from "@/stores/toggle";
// import DrawerComponent from "@/components/DrawerComponent.vue";

const storeToggle = useToggle();

const route = useRoute();
const openMenu = ref(false);

const showSidebar = ref(false);
const { accounts } = useMsal();

const accountExists = computed(() => {
  return accounts.value.length > 0;
});

const name = computed(() => {
  if (accounts.value.length > 0) {
    const name = accounts.value[0].idTokenClaims.given_name;
    if (name) {
      return name.split(" ")[0];
    }
  }
  return "";
});

const navbarOpen = ref(false);
function setNavbarOpen() {
  navbarOpen.value = !navbarOpen.value;
}

const crumbs = computed(() => {
  const pathArray = route.path.split("/");
  pathArray.shift();
  return pathArray.reduce((breadcrumbArray, path, idx) => {
    const isLast = idx === pathArray.length - 1;
    const title = isLast && route.query.name ? route.query.name : path;
    breadcrumbArray.push({
      title,
      disabled: isLast,
      href: breadcrumbArray[idx - 1]
        ? `/${breadcrumbArray[idx - 1].title}/${path}`
        : `/${path}`,
    });
    return breadcrumbArray;
  }, []);
});

// import useDetectOutsideClick from "../composables/useDetectOutsideClick";
// import { ref } from "vue";

// const toggleButton = ref(false);

// useDetectOutsideClick(toggleButton, () => {

// });
</script>
<style>
.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: all 0.2s linear;
}
.fade-leave-to {
  transition: all 0.2s linear;
  opacity: 0;
}
</style>
