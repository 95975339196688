import { defineStore } from "pinia";

export const useToggle = defineStore({
  id: "toggle",
  state: () => ({
    darkMode:
      localStorage.getItem("is-dark") !== null
        ? JSON.parse(localStorage.getItem("is-dark")!)
        : window.matchMedia("(prefers-color-scheme: dark)").matches,
    autoUpdate: false,
  }),
  getters: {
    autoUpdateStatus(state) {
      return state.autoUpdate;
    },
  },
  actions: {
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      localStorage.setItem("is-dark", JSON.stringify(this.darkMode));
    },
    toggleAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
    },
  },
});
