import { createRouter, createWebHistory } from "vue-router";
import { registerGuard } from "./Guard";

const router = createRouter({
  // history: createWebHistory(msalConfig.auth.baseURL),
  history: createWebHistory("/"),
  routes: [
    {
      path: "/profile",
      name: "Profile",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/ProfileView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/",
      redirect: "/overview",
    },
    {
      path: "/overview",
      name: "Overview",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/OverviewView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/events",
      name: "Events",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/EventsView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Settlement",
      name: "Settlements",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/SettlementView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/location/:id",
      name: "Location",
      component: () => import("../views/GatewayDataView.vue"),
      meta: {
        requiresAuth: true,
        watchParam: "id",
      },
      // beforeEnter: (to, from, next) => {
      //   console.log("Manage Route guard");
      //   console.log(to);
      //   console.log(to.params.id);
      //   console.log(from);
      //   next();
      // },
    },
    {
      path: "/location/",
      name: "Locations",
      component: () => import("../views/LocationsView.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/404",
      name: "Not Found",
      component: () => import("../views/NotFound.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/404",
    },
    {
      path: "/failed",
      name: "failed",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/Failed.vue"),
      // meta: {
      //   requiresAuth: true,
      // },
    },
  ],
  //There is a warning about:
  // [Vue Router warn]: The selector "" is invalid. If you are using an id selector, make sure to escape it. You can find more information about escaping characters in selectors at https://mathiasbynens.be/notes/css-escapes or use CSS.escape (https://developer.mozilla.org/en-US/docs/Web/API/CSS/escape).
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return {
  //       el: to.hash,
  //       behavior: "smooth",
  //     };
  //   }
  // },
});

registerGuard(router);

export default router;
function setup() {
  throw new Error("Function not implemented.");
}
