import { createApp, provide, h } from "vue";
import { createPinia } from "pinia";

import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance, loginRequest } from "./includes/azureConfig";

import { AuthError, EventType } from "@azure/msal-browser";
import type { AuthenticationResult } from "@azure/msal-browser";
import { CustomNavigationClient } from "./router/NavigationClient";
import FontAwesomeIcon from "./includes/fontawesome-icons";

import VueApexCharts from "vue3-apexcharts";

import App from "./App.vue";
import router from "./router";

import vuetify from "./plugins/vuetify";
import tippy from "./plugins/tippy";
import { loadFonts } from "./plugins/webfontloader";

import "./assets/base.css";
import "./assets/main.css";
import Configuration from "@/configuration";

import { DefaultApolloClient } from "@vue/apollo-composable";

import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { apolloClient } from "./plugins/apollo"; // import the Apollo Client instance

// import * as Sentry from "@sentry/vue";
import { initSentry } from "./plugins/sentry";

// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback(async (event) => {
  if (
    event.error instanceof AuthError &&
    ((event.eventType === EventType.LOGIN_FAILURE &&
      (event.error.errorCode === "access_denied" ||
        event.error.errorCode === "user_cancelled")) ||
      event.eventType === EventType.ACQUIRE_TOKEN_FAILURE)
  ) {
    try {
      if (
        event.error.errorCode === "access_denied" &&
        event.error.errorMessage.indexOf("AADB2C90118") > -1
      ) {
        // Handle reset password
        await msalInstance.loginRedirect({
          scopes: ["openid", "profile"],
          authority: Configuration.value("authority"),
        });
      } else if (event.error.errorMessage.indexOf("AADB2C90027")) {
        await msalInstance.loginRedirect({
          scopes: ["openid", "profile"],
          authority: Configuration.value("authority"),
        });
      } else {
        return msalInstance
          .loginRedirect(loginRequest)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
        // Redirect to login page
        // router.push({ name: "Login" }); // Replace "Login" with the actual name of your login route
      }
    } catch (passwordResetError) {
      console.error(passwordResetError);
    }
  }
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

loadFonts();

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
});
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
if (
  process.env.NODE_ENV === "production" &&
  Configuration.value("redirectUri") === "https://dev-dashboard.phlit.io/"
) {
  initSentry(app);
}
app
  .use(pinia)
  .use(router)
  .use(msalPlugin, msalInstance)
  .use(VueApexCharts)
  .use(vuetify)
  // .use(Vue3GeoLocation)
  .component("FontAwesomeIcon", FontAwesomeIcon);

router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount("#app");
});
