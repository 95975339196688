import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faPhone,
  faPaperPlane,
  faMapMarkerAlt,
  faBars,
  faArrowRightFromBracket,
  faUser,
  faChartPie,
  faClockRotateLeft,
  faCircleInfo,
  faGear,
  faChevronUp,
  faChevronDown,
  faArrowCircleLeft,
  faArrowCircleDown,
  faBolt,
  faSun,
  faMoon,
  faEllipsisVertical,
  faHouse,
  faChartLine,
  faCalendarDay,
  faCalendar,
  faLocationDot,
  faGavel,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

library.add(
  faPhone,
  faPaperPlane,
  faMapMarkerAlt,
  faLinkedinIn,
  faBars,
  faArrowRightFromBracket,
  faUser,
  faChartPie,
  faClockRotateLeft,
  faCircleInfo,
  faGear,
  faChevronUp,
  faChevronDown,
  faArrowCircleLeft,
  faArrowCircleDown,
  faBolt,
  faSun,
  faMoon,
  faEllipsisVertical,
  faHouse,
  faChartLine,
  faCalendarDay,
  faCalendar,
  faLocationDot,
  faGavel,
  faEye,
  faEyeSlash
);

export default FontAwesomeIcon;
