// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  // theme: {
  //   themes: {
  //     light: {
  //       dark: true,
  //       // colors: {
  //       //   primary: colors.red.darken1, // #E53935
  //       // },
  //     },
  //   },
  // },
});
