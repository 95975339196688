import { PublicClientApplication, type AccountInfo } from "@azure/msal-browser";
import Configuration from "@/configuration";
import { reactive } from "vue";

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: Configuration.value("clientId"),
    authority: Configuration.value("authority"),
    redirectUri: Configuration.value("redirectUri"), // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: Configuration.value("postLogoutRedirectUri"), // Must be registered as a SPA redirectURI on your app registration
    knownAuthorities: [Configuration.value("knownAuthorities")],
    passwordAuthority: Configuration.value("passwordAuthority"),
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: [
    Configuration.value("clientId"),
    "openid",
    "offline_access",
    "profile",
  ],
};

export const state = reactive({
  isAuthenticated: false,
  user: null as AccountInfo | null,
});

export const tokenRequest = {
  // endpoint: "https://localhost:5174", //change for deploy version
  scopes: [Configuration.value("apiScope"), "openid", "offline_access"],
};
