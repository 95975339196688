<template>
  <div class="border-b border-b-gray-100 bg-gray-600"></div>
  <li class="pt-1 pb-1">
    <a
      class="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/20 transition-colors duration-200 text-light-100 cursor-pointer"
      @click="logoutRedirect"
    >
      <font-awesome-icon
        class="w-4 h-4 text-light-100 transition duration-75 group-hover:text-gray-900 hover:text-black"
        :icon="['fas', 'fa-arrow-right-from-bracket']"
      />
      <span class="">{{ logout }}</span>
    </a>
  </li>
</template>

<script setup lang="ts">
import { useMsal } from "../composables/useMsal";

const { instance } = useMsal();

const logout = "Logout";
const logoutRedirect = () => {
  instance.logoutRedirect();
};
</script>
