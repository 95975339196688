import { computed } from "vue";

import { useQuery } from "@vue/apollo-composable";
import { GATEWAYS } from "@/graphql/queries/gateways.js";
import { provideApolloClient } from "@vue/apollo-composable";
import { apolloClient } from "@/plugins/apollo"; // import the Apollo Client instance

export const useGateways = () => {
  const { result, loading, error, refetch, onResult } = provideApolloClient(
    apolloClient
  )(() => useQuery(GATEWAYS, {}, { fetchPolicy: "network-only" }));
  // const gateways = computed(() => result.value?.gateways || []);
  const gateways = computed(() => {
    if (result.value?.gateways) {
      return result.value.gateways.filter(
        (gateway) => gateway.type.name !== "pump"
      );
    }
    return [];
  });
  const getGatewayByName = (name) => {
    if (result.value?.gateways) {
      return result.value.gateways.find((gateway) => gateway.name === name);
    }
    return null; // or handle if data is still loading or if no data is fetched
  };
  return {
    gateways,
    loading,
    error,
    getGatewayByName,
    refetch,
    onResult,
  };
};
