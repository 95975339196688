export default class Configuration {
  static get CONFIG() {
    return {
      clientId: "$VITE_APP_MSAL_CLIENT_ID",
      authority: "$VITE_APP_MSAL_LOGIN_AUTHORITY",
      passwordAuthority: "$VITE_APP_MSAL_PASSWORD_RESET_AUTHORITY",
      knownAuthorities: "$VITE_APP_MSAL_KNOWN_AUTHORITY",
      redirectUri: "$VITE_APP_REDIRECT_URI",
      apiScope: "$VITE_APP_API_SCOPE",
      baseURL: "$VITE_APP_BASE_URL",
      postLogoutRedirectUri: "$VITE_APP_LOGOUT_REDIRECT_URI",
    };
  }

  static value(name) {
    const config = this.CONFIG;
    if (!(name in config)) {
      console.log(`Configuration: There is no key named "${name}"`);
      return;
    }
    const value = config[name];

    if (!value) {
      console.log(`Configuration: Value for "${name}" is not defined`);
      return;
    }

    if (value.startsWith("$VITE_APP_")) {
      const envName = value.substring(1);

      // Check if running in a Node.js environment (like vite.config.ts)
      if (typeof process !== "undefined" && process.env[envName]) {
        return process.env[envName];
      }

      // For regular Vite application environment
      if (
        typeof import.meta !== "undefined" &&
        import.meta.env &&
        import.meta.env[envName]
      ) {
        return import.meta.env[envName];
      }
      console.log(
        `Configuration: Environment variable "${envName}" is not defined`
      );
    } else {
      return value;
    }
  }
}
