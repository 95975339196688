<template>
  <div
    v-if="showSidebar && isSmallScreen"
    class="bg-gray-900 opacity-50 fixed inset-0 z-10 dark:bg-dark-100"
  ></div>
  <aside
    class="dark:bg-dark-100"
    :class="{
      'flex flex-col justify-between': showSidebar,
      'bg-gray-900 text-light-100 w-64': showSidebar,
      ' sm:sticky top-0 z-20 fixed': showSidebar,
      'sm:h-[calc(100vh)] h-full w-64': showSidebar,
      'transition-transform .3s ease-in-out sm:translate-x-0': showSidebar,
      '-translate-x-full': !showSidebar,
      hidden: !showSidebar,
    }"
    ref="componentRef"
  >
    <div class="flex w-64 flex-col text-light-100 px-5 mt-16">
      <div
        class="flex justify-center items-center h-32 bg-gray-900 dark:bg-dark-100"
      >
        <RouterLink to="/overview">
          <img class="w-40" src="@/assets/p-logo.svg" />
        </RouterLink>
      </div>
      <div>
        <ul class="space-y-2">
          <AccordionItem
            v-for="(item, index) in menu"
            :key="index"
            :item="item"
          ></AccordionItem>
          <LogoutButton />
        </ul>
      </div>
    </div>
  </aside>
</template>
<script setup lang="ts">
import { useMsal } from "../composables/useMsal";
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import { menuItems } from "@/includes/constants";
import LogoutButton from "@/components/LogoutButton.vue";
import AccordionItem from "@/components/AccordionItem.vue";
import { useGateways } from "@/api/Gateways/getGateways.js";
const {
  gateways,
  loading: assetsLoading,
  error: assetsError,
  getGatewayByName,
  refetch: assetsRefetch,
} = useGateways();

const props = defineProps({
  showSidebar: Boolean,
});

const isSmallScreen = ref(false);

const checkScreenSize = () => {
  isSmallScreen.value = window.innerWidth < 768;
};
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
onBeforeUnmount(() => {
  window.removeEventListener("resize", checkScreenSize);
});
const { accounts } = useMsal();
const userId = accounts.value[0].idTokenClaims.sub;

const gatewayItem = ref([]);
const menu = ref(menuItems);

watch(gateways, (newVal, oldVal) => {
  if (!(typeof newVal === "object" && newVal !== null)) {
    return;
  }
  const gatewaysArray = [];
  for (const key in newVal) {
    if (newVal.hasOwnProperty(key)) {
      const gateway = newVal[key];

      gatewaysArray.push({
        id: gateway.id,
        name: gateway.name,
        assetId: gateway.assets[0].id,
      });
    }
  }

  const gatewaysItem = menu.value.find((item) => item.id === "location");
  gatewaysItem.submenu = gatewaysArray.map((gateway) => ({
    path: `/location/${gateway.id}`,
    name: gateway.name,
  }));
});

onMounted(() => {
  window.addEventListener("resize", checkScreenSize);
  checkScreenSize();
});
</script>
