<template>
  <div id="app" class="flex" :class="{ dark: storeToggle.darkMode }">
    <app-header></app-header>
  </div>
</template>

<script setup lang="ts">
import AppHeader from "./components/AppHeader.vue";

import { ref } from "vue";
import { useToggle } from "@/stores/toggle";

const storeToggle = useToggle();
const sidebarOpen = ref(true);
</script>
<style>
html {
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  height: 100%;
}
#app {
  overflow: visible;
}
.v-navigation-drawer__scrim {
  position: fixed !important;
}
</style>
<!-- max-width: calc(100% - 48px); for the drawer in the future-->
