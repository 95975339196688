import gql from "graphql-tag";

export const GATEWAYS = gql`
  query Gateways {
    gateways {
      id
      name
      latitude
      longitude
      type {
        name
        icon
      }
      assets {
        id
        name
      }
    }
  }
`;
