<template>
  <li>
    <div class="flex items-center">
      <router-link
        :key="item.path"
        :to="{ path: item.path, query: { name: item.name } }"
        class="flex flex-grow py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-700/50 transition-colors duration-200 text-light-100 cursor-pointer"
        active-class="bg-green-50"
      >
        <font-awesome-icon
          v-if="item.icon"
          class="w-4 h-4 text-light-100 transition duration-75 group-hover:text-gray-900 items-end"
          :icon="['fas', item.icon]"
        />
        <span>{{ item.name }}</span>
      </router-link>
      <div
        v-if="item.submenu && item.submenu.length > 0"
        class="ml-auto py-3 px-3 cursor-pointer"
        @click="toggleItem"
      >
        <font-awesome-icon
          :icon="!isOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"
          @click="!toggleItem"
          class="ml-1.5 mr-1.5"
        />
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <ul v-show="isOpen" class="ml-7">
        <AccordionItem
          v-for="(subItem, subIndex) in item.submenu"
          :key="subIndex"
          :item="subItem"
        />
      </ul>
    </transition>
  </li>
</template>
<script setup>
import { ref, reactive, computed, watchEffect } from "vue";

const isOpen = ref(false);
const props = defineProps({
  item: Object,
});
const hasChildren = computed(() => {
  return props.item.submenu && props.item.submenu.length > 0;
});

const toggleItem = () => {
  if (hasChildren) {
    isOpen.value = !isOpen.value;
  }
};
</script>
