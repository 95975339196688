import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import Configuration from "@/configuration";
import { msalInstance } from "../includes/azureConfig";

const PUMP_READ_SCOPE = Configuration.value("apiScope");
const baseURL = Configuration.value("baseURL");

async function getToken() {
  const currentAccount = msalInstance.getAllAccounts()[0];
  const tokenRequest = {
    scopes: [PUMP_READ_SCOPE, "openid", "offline_access"],
    account: currentAccount,
  };
  const response = await msalInstance.acquireTokenSilent(tokenRequest);
  return response.accessToken;
}

const httpLink = createHttpLink({
  uri: baseURL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
  };
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  connectToDevTools: true,
});
